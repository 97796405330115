/* Main container */
.product-catalog-container {
  position: relative;
  padding: 30px 0;
  max-width: 1200px;
  margin: 60px auto 0 auto;
}

/* Section tabs styling */
.section-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #dee2e6;
}

.section-tab {
  padding: 12px 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 3px solid transparent;
  color: #6c757d;
}

.section-tab:hover {
  color: #007bff;
}

.section-tab.active {
  color: #007bff;
  border-bottom-color: #007bff;
}

/* Section content */
.section-content {
  padding: 20px;
}

.section-title {
  text-align: center;
  margin-bottom: 30px;
  color: #343a40;
  font-weight: 600;
}

/* Products grid */
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
}

/* Product card styling */
.product-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: white;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.product-image {
  height: 200px;
  overflow: hidden;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.product-card:hover .product-image img {
  transform: scale(1.05);
}

.product-info {
  padding: 20px;
}

.product-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #343a40;
}

.product-ranges h4 {
  font-size: 16px;
  color: #495057;
  margin-bottom: 10px;
}

.product-ranges ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.product-ranges li {
  padding: 5px 0;
  color: #6c757d;
  border-bottom: 1px dashed #e9ecef;
}

.product-ranges li:last-child {
  border-bottom: none;
}

.view-details-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-weight: 500;
}

.view-details-btn:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section-tabs {
    flex-wrap: wrap;
  }
  
  .section-tab {
    padding: 10px 15px;
    font-size: 16px;
  }
  
  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 576px) {
  .products-grid {
    grid-template-columns: 1fr;
  }
}